import { forwardRef, SVGProps } from 'react';

export interface FigmaSearchIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaSearchIcon = forwardRef<SVGSVGElement, FigmaSearchIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7ZM10.8744 11.5815C9.82908 12.4664 8.47685 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 8.47685 12.4664 9.82908 11.5815 10.8744L15.8536 15.1464L15.1464 15.8536L10.8744 11.5815Z"
      clip-rule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaSearchIcon.displayName = 'FigmaSearchIcon';
