import { forwardRef, SVGProps } from 'react';

export interface FigmaForwardIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaForwardIcon = forwardRef<SVGSVGElement, FigmaForwardIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M9.93947 7.99998L5.29297 3.35358L6.00007 2.64648L11.3537 7.99998L6.00007 13.3536L5.29297 12.6465L9.93947 7.99998Z"
      clip-rule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaForwardIcon.displayName = 'FigmaForwardIcon';
