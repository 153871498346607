import { forwardRef, SVGProps } from 'react';

export interface FigmaEllipsisIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaEllipsisIcon = forwardRef<SVGSVGElement, FigmaEllipsisIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M3.5 8C3.5 8.82843 2.82843 9.5 2 9.5C1.17157 9.5 0.5 8.82843 0.5 8C0.5 7.17157 1.17157 6.5 2 6.5C2.82843 6.5 3.5 7.17157 3.5 8ZM9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8ZM14 9.5C14.8284 9.5 15.5 8.82843 15.5 8C15.5 7.17157 14.8284 6.5 14 6.5C13.1716 6.5 12.5 7.17157 12.5 8C12.5 8.82843 13.1716 9.5 14 9.5Z"
      clip-rule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaEllipsisIcon.displayName = 'FigmaEllipsisIcon';
