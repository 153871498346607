import { forwardRef, SVGProps } from 'react';

export interface FigmaCheckmarkIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaCheckmarkIcon = forwardRef<SVGSVGElement, FigmaCheckmarkIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M13.2067 5.20718L7.70669 10.7072L6.99959 11.4143L6.29248 10.7072L3.29248 7.70718L4.70669 6.29297L6.99959 8.58586L11.7925 3.79297L13.2067 5.20718Z"
      clip-rule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaCheckmarkIcon.displayName = 'FigmaCheckmarkIcon';
