import { theme as ZephyrTheme } from '@air/zephyr';

import breakpoints from './breakpoints';
import buttons from './buttons';
import buttonSizes from './buttonSizes';
import fontSizes from './fontSizes';
import letterSpacing from './letterSpacing';
import widths from './widths';

const { colors, fonts, fontWeights, radii, space, variants } = ZephyrTheme;

/** @see https://styled-system.com/theme-specification#key-reference */
export const theme = {
  breakpoints,
  colors: {
    ...colors,
    pigeon150: '#CECECE',
    pigeon650: '#3F3E3E',
    pigeon850: '#1A1A1A',
    /**
     * @todo See if we actually need this with the Box component
     */
    inherit: 'inherit',
    currentColor: 'currentColor',
  },
  fonts,
  fontSizes,
  fontWeights,
  letterSpacing,
  radii,
  space,
  /**
   * @deprecated
   */
  widths,

  // custom
  buttons,
  buttonSizes,
  variants,
};

export type ThemeObject = typeof theme;
