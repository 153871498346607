import { forwardRef, SVGProps } from 'react';

export interface FigmaMinusIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaMinusIcon = forwardRef<SVGSVGElement, FigmaMinusIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path fill="currentColor" d="M13.5 8.5H2.5V7.5H13.5V8.5Z" clip-rule="evenodd" fillRule="evenodd" />
  </svg>
));

FigmaMinusIcon.displayName = 'FigmaMinusIcon';
