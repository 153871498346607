import { forwardRef, SVGProps } from 'react';

export interface FigmaLibraryIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaLibraryIcon = forwardRef<SVGSVGElement, FigmaLibraryIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M7.41018 13.7248H8.59389L8.62175 13.6933C8.87975 13.4051 9.18587 13.1551 9.53046 12.9541C10.9218 12.1425 13.2997 12.0985 14.6 13.0563H15H16V12.0563V4.39743C16 4.39743 15.25 2.27979 11.84 2.27979C10.1225 2.27979 9.16092 2.81701 8.62954 3.35029C8.23264 3.74861 8.07573 4.14472 8.02303 4.31312H7.97697C7.92427 4.14472 7.76736 3.74861 7.37046 3.35029C6.83908 2.81701 5.87754 2.27979 4.16 2.27979C0.75 2.27979 0 4.39743 0 4.39743V12.0468V13.0468H1H1.4C2.70139 12.0883 5.08128 12.1341 6.47175 12.9486C6.81683 13.1507 7.12315 13.402 7.38098 13.6916L7.41018 13.7248ZM8.5 4.59643V12.4458C8.6666 12.3166 8.84249 12.1977 9.02659 12.0904C9.9103 11.5749 11.0388 11.3334 12.1072 11.3417C13.0552 11.3491 14.0723 11.5547 14.9014 12.0563H15V4.627C14.994 4.61685 14.9875 4.60619 14.9806 4.59505C14.9023 4.46929 14.7653 4.28536 14.5456 4.09582C14.1234 3.73164 13.3187 3.27979 11.84 3.27979C10.3604 3.27979 9.66629 3.73038 9.34547 4.04859C9.17399 4.21868 9.07704 4.38256 9.02499 4.49297C8.99893 4.54827 8.98451 4.58936 8.97809 4.60955C8.97491 4.61957 8.97379 4.62412 8.97425 4.62232L8.9761 4.6146L8.97782 4.60687L8.97885 4.602L8.97941 4.5993L8.9797 4.59788C8.97973 4.59776 8.97976 4.59763 8.97447 4.59643H8.5ZM7.5 4.59643H7.02553C7.02292 4.59702 7.0216 4.59735 7.02094 4.59755C7.02027 4.59776 7.02028 4.59782 7.0203 4.59788L7.02059 4.5993L7.02115 4.602L7.02218 4.60687L7.0239 4.6146L7.02575 4.62232L7.02585 4.6227C7.02585 4.6227 7.02464 4.61813 7.02191 4.60955C7.01549 4.58936 7.00107 4.54827 6.97501 4.49297C6.92296 4.38256 6.82601 4.21868 6.65453 4.04859C6.33371 3.73038 5.63964 3.27979 4.16 3.27979C2.68128 3.27979 1.87662 3.73164 1.45441 4.09582C1.23469 4.28536 1.0977 4.46929 1.0194 4.59505C1.01247 4.60619 1.00601 4.61685 1 4.627V12.0468H1.09865C1.92851 11.5448 2.94667 11.3395 3.89543 11.3328C4.96455 11.3253 6.09369 11.5682 6.97719 12.0857C7.15995 12.1928 7.33456 12.3111 7.5 12.4397V4.59643Z"
      clip-rule="evenodd"
      fillRule="evenodd"
    />
  </svg>
));

FigmaLibraryIcon.displayName = 'FigmaLibraryIcon';
