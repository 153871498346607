import { theme } from '@air/zephyr';
import { rgba } from 'polished';
import { CSSProperties } from 'react';

export type ButtonName = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19;

type ButtonBase = Pick<
  CSSProperties,
  'borderColor' | 'color' | 'backgroundColor' | 'stroke' | 'cursor' | 'textDecoration'
>;

type Button = ButtonBase & {
  '&:hover'?: ButtonBase;
  '&.focus-visible'?: ButtonBase;
  '&:active'?: ButtonBase;
  '&:disabled'?: ButtonBase;
};

const buttons: { [key in ButtonName]: Button } = {
  // Reset
  0: {},

  // Ghost
  1: {
    borderColor: theme.colors.transparent,
    color: theme.colors.pigeon800,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.pigeon050,
      color: theme.colors.black,
      backgroundColor: theme.colors.pigeon050,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: theme.colors.pigeon050,
      color: theme.colors.black,
      backgroundColor: theme.colors.pigeon050,
      stroke: 'currentColor',
    },
    '&:active': {
      borderColor: theme.colors.pigeon100,
      color: theme.colors.black,
      backgroundColor: theme.colors.pigeon100,
      stroke: 'currentColor',
    },
  },

  // Primary
  2: {
    borderColor: theme.colors.jay500,
    color: theme.colors.white,
    backgroundColor: theme.colors.jay500,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.jay600,
      backgroundColor: theme.colors.jay600,
    },
    '&.focus-visible': {
      borderColor: theme.colors.jay600,
      backgroundColor: theme.colors.jay600,
    },
    '&:active': {
      borderColor: theme.colors.jay700,
      backgroundColor: theme.colors.jay700,
    },
    '&:disabled': {
      borderColor: theme.colors.jay300,
      color: theme.colors.white,
      backgroundColor: theme.colors.jay300,
      stroke: 'currentColor',
    },
  },

  // Primary, outline
  3: {
    borderColor: theme.colors.jay600,
    color: theme.colors.jay600,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.jay700,
      color: theme.colors.jay700,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: theme.colors.jay700,
      color: theme.colors.jay700,
      stroke: 'currentColor',
    },
    '&:active': {
      borderColor: theme.colors.jay800,
      color: theme.colors.jay800,
      stroke: 'currentColor',
    },
    '&:disabled': {
      borderColor: theme.colors.pigeon100,
      color: theme.colors.pigeon400,
      stroke: 'currentColor',
    },
  },

  // Secondary
  4: {
    borderColor: theme.colors.pigeon050,
    color: theme.colors.pigeon500,
    backgroundColor: theme.colors.pigeon050,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.pigeon100,
      color: theme.colors.pigeon700,
      backgroundColor: theme.colors.pigeon100,
    },
    '&.focus-visible': {
      borderColor: theme.colors.pigeon100,
      color: theme.colors.pigeon700,
      backgroundColor: theme.colors.pigeon100,
    },
    '&:active': {
      borderColor: theme.colors.pigeon200,
      color: theme.colors.pigeon800,
      backgroundColor: theme.colors.pigeon200,
    },
    '&:disabled': {
      borderColor: theme.colors.pigeon050,
      color: theme.colors.pigeon400,
      backgroundColor: theme.colors.pigeon050,
      stroke: 'currentColor',
    },
  },

  // Secondary, outline
  5: {
    borderColor: theme.colors.pigeon200,
    color: theme.colors.pigeon500,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.pigeon400,
      color: theme.colors.pigeon700,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: theme.colors.pigeon400,
      color: theme.colors.pigeon700,
      stroke: 'currentColor',
    },
    '&:active': {
      borderColor: theme.colors.pigeon500,
      color: theme.colors.pigeon800,
      stroke: 'currentColor',
    },
    '&:disabled': {
      borderColor: theme.colors.pigeon100,
      color: theme.colors.pigeon400,
      stroke: 'currentColor',
    },
  },

  // Secondary, outline, fill
  6: {
    borderColor: theme.colors.pigeon400,
    color: theme.colors.pigeon400,
    backgroundColor: rgba(theme.colors.white, 0.9),
    stroke: 'currentColor',
    '&:hover': {
      color: theme.colors.pigeon700,
      backgroundColor: theme.colors.white,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      color: theme.colors.pigeon700,
      backgroundColor: theme.colors.white,
      stroke: 'currentColor',
    },
  },

  // Secondary, outline, dark
  7: {
    borderColor: theme.colors.pigeon300,
    color: theme.colors.pigeon800,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.pigeon600,
      color: theme.colors.black,
      backgroundColor: theme.colors.transparent,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: theme.colors.pigeon600,
      color: theme.colors.black,
      backgroundColor: theme.colors.transparent,
      stroke: 'currentColor',
    },
  },

  // Blue, outline
  8: {
    borderColor: theme.colors.pigeon300,
    color: theme.colors.pigeon600,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.pigeon400,
      color: theme.colors.jay900,
      backgroundColor: theme.colors.jay050,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: theme.colors.jay500,
      color: theme.colors.jay900,
      backgroundColor: theme.colors.jay050,
      stroke: 'currentColor',
    },
  },

  // Danger
  9: {
    borderColor: theme.colors.flamingo600,
    color: theme.colors.white,
    backgroundColor: theme.colors.flamingo600,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.flamingo700,
      backgroundColor: theme.colors.flamingo700,
    },
    '&.focus-visible': {
      borderColor: theme.colors.flamingo700,
      backgroundColor: theme.colors.flamingo700,
    },
    '&:active': {
      borderColor: theme.colors.flamingo800,
      backgroundColor: theme.colors.flamingo800,
    },
    '&:disabled': {
      borderColor: theme.colors.pigeon050,
      color: theme.colors.pigeon400,
      backgroundColor: theme.colors.pigeon050,
      stroke: 'currentColor',
    },
  },

  // Inactive
  10: {
    borderColor: theme.colors.pigeon050,
    color: theme.colors.pigeon400,
    backgroundColor: theme.colors.pigeon050,
    stroke: 'currentColor',
    cursor: 'default',
  },

  // Inactive, outline
  11: {
    borderColor: theme.colors.pigeon100,
    color: theme.colors.pigeon300,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    cursor: 'default',
  },

  // Sidebar actions
  12: {
    borderColor: theme.colors.transparent,
    color: rgba(theme.colors.white, 0.6),
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.jay800,
      color: theme.colors.white,
      backgroundColor: theme.colors.jay800,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: theme.colors.jay800,
      color: theme.colors.white,
      backgroundColor: theme.colors.jay800,
      stroke: 'currentColor',
    },
    '&:active': {
      borderColor: theme.colors.jay900,
      color: theme.colors.white,
      backgroundColor: theme.colors.jay900,
      stroke: 'currentColor',
    },
  },

  // Edit buttons
  13: {
    color: theme.colors.pigeon500,
    stroke: 'currentColor',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.colors.pigeon600,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      color: theme.colors.pigeon600,
      stroke: 'currentColor',
    },
    '&:active': {
      color: theme.colors.pigeon700,
      stroke: 'currentColor',
    },
  },

  // Link buttons
  14: {
    stroke: 'currentColor',
    textDecoration: 'underline',
    '&:hover': {
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      stroke: 'currentColor',
    },
    '&:active': {
      stroke: 'currentColor',
    },
  },

  // Pills
  15: {
    borderColor: theme.colors.pigeon050,
    color: theme.colors.pigeon500,
    backgroundColor: theme.colors.pigeon050,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: rgba(theme.colors.pigeon100, 0.8),
      backgroundColor: rgba(theme.colors.pigeon100, 0.8),
    },
    '&.focus-visible': {
      borderColor: rgba(theme.colors.pigeon100, 0.8),
      backgroundColor: rgba(theme.colors.pigeon100, 0.8),
    },
    '&:active': {
      borderColor: rgba(theme.colors.pigeon200, 0.7),
      backgroundColor: rgba(theme.colors.pigeon200, 0.7),
    },
  },
  16: {
    borderColor: rgba(theme.colors.peacock050, 0.2),
    color: theme.colors.peacock700,
    backgroundColor: rgba(theme.colors.peacock050, 0.2),
    stroke: 'currentColor',
    '&:hover': {
      borderColor: rgba(theme.colors.peacock050, 0.4),
      backgroundColor: rgba(theme.colors.peacock050, 0.4),
    },
    '&.focus-visible': {
      borderColor: rgba(theme.colors.peacock050, 0.4),
      backgroundColor: rgba(theme.colors.peacock050, 0.4),
    },
    '&:active': {
      borderColor: rgba(theme.colors.peacock050, 0.6),
      backgroundColor: rgba(theme.colors.peacock050, 0.6),
    },
  },

  // Blue, outline
  17: {
    borderColor: theme.colors.jay200,
    color: theme.colors.jay500,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: theme.colors.jay100,
      color: theme.colors.jay600,
      backgroundColor: theme.colors.jay100,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: theme.colors.jay100,
      color: theme.colors.jay600,
      backgroundColor: theme.colors.jay100,
      stroke: 'currentColor',
    },
    '&:active': {
      borderColor: theme.colors.jay200,
      color: theme.colors.jay700,
      backgroundColor: theme.colors.jay200,
      stroke: 'currentColor',
    },
    '&:disabled': {
      borderColor: theme.colors.pigeon100,
      color: theme.colors.pigeon400,
      stroke: 'currentColor',
    },
  },

  // Asset label
  18: {
    borderColor: theme.colors.pigeon100,
    backgroundColor: theme.colors.transparent,
    stroke: 'currentColor',
    '&:hover': {
      borderColor: rgba(theme.colors.black, 0.5),
      color: theme.colors.black,
      backgroundColor: theme.colors.transparent,
      stroke: 'currentColor',
    },
    '&.focus-visible': {
      borderColor: rgba(theme.colors.black, 0.5),
      color: theme.colors.black,
      backgroundColor: theme.colors.transparent,
      stroke: 'currentColor',
    },
    '&:active': {
      borderColor: rgba(theme.colors.black, 0.75),
      color: theme.colors.black,
      backgroundColor: theme.colors.transparent,
      stroke: 'currentColor',
    },
  },

  // Asset icon buttons
  19: {
    backgroundColor: rgba(theme.colors.black, 0.7),
    '&:hover': {
      backgroundColor: rgba(theme.colors.black, 0.9),
    },
    '&.focus-visible': {
      backgroundColor: rgba(theme.colors.black, 0.9),
    },
    '&:active': {
      backgroundColor: rgba(theme.colors.black, 0.9),
    },
  },
};

export default buttons;
