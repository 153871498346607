import classNames from 'classnames';
import { ComponentPropsWithRef, ElementRef, forwardRef } from 'react';

export const SPINNER_ELEMENT_TAG = 'svg';

export type SpinnerProps = Omit<ComponentPropsWithRef<typeof SPINNER_ELEMENT_TAG>, 'viewBox' | 'xmlns'>;

export const Spinner = forwardRef<ElementRef<typeof SPINNER_ELEMENT_TAG>, SpinnerProps>(
  ({ className, ...restOfProps }, forwardedRef) => {
    return (
      <svg
        aria-label="Loading"
        aria-live="polite"
        className={classNames('block origin-center animate-spinnerRotate motion-reduce:animate-none', className)}
        height={24}
        ref={forwardedRef}
        role="alert"
        width={24}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
        {...restOfProps}
      >
        <circle
          className="origin-center animate-spinnerDash motion-reduce:animate-none"
          cx={33}
          cy={33}
          fill="none"
          r={29}
          stroke="currentColor"
          strokeDasharray={187}
          strokeDashoffset={0}
          strokeWidth={8}
          strokeLinecap="round"
        />
      </svg>
    );
  },
);

Spinner.displayName = 'Spinner';
