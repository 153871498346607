import { forwardRef, SVGProps } from 'react';

export interface FigmaHiddenIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaHiddenIcon = forwardRef<SVGSVGElement, FigmaHiddenIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <g clip-path="url(#a)">
      <path
        fill="currentColor"
        d="M13.5085 7.80119C14.0639 7.27364 14.5436 6.66723 14.9295 6H13.7453C12.4798 7.81421 10.378 9 7.99986 9C5.62172 9 3.51992 7.81421 2.25446 6H1.07022C1.45619 6.66728 1.93589 7.27373 2.49134 7.80131L0.896484 9.39623L1.6036 10.1033L3.26094 8.44592C3.97166 8.96931 4.77213 9.37804 5.63506 9.64469L5.01804 11.866L5.98156 12.1336L6.60775 9.87929C7.05984 9.95862 7.52501 10 7.99986 10C8.47467 10 8.9398 9.95863 9.39185 9.87931L10.018 12.1336L10.9816 11.866L10.3646 9.64473C11.2276 9.37806 12.0281 8.96928 12.7389 8.44582L14.3965 10.1033L15.1036 9.39621L13.5085 7.80119Z"
        clip-rule="evenodd"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="16" height="16" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
));

FigmaHiddenIcon.displayName = 'FigmaHiddenIcon';
