import { forwardRef, SVGProps } from 'react';

export interface FigmaArrowDownIconProps extends Omit<SVGProps<SVGSVGElement>, 'css'> {}

export const FigmaArrowDownIcon = forwardRef<SVGSVGElement, FigmaArrowDownIconProps>((props, ref) => (
  <svg ref={ref} {...props} fill="none" aria-hidden="true" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M7.99998 12.7071L7.64642 12.3536L4.64642 9.35355L5.35353 8.64645L7.49998 10.7929L7.49998 2.5H8.49998L8.49998 10.7929L10.6464 8.64645L11.3535 9.35355L8.35353 12.3536L7.99998 12.7071Z"
      clip-rule="evenodd"
      fillOpacity="0.8"
      fillRule="evenodd"
    />
  </svg>
));

FigmaArrowDownIcon.displayName = 'FigmaArrowDownIcon';
